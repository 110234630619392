<template>
	<div class="wrap__content" v-bind:class="{ 'wrap__content--preloader': !applications }">
		<v-preloader v-if="!applications" :message="errorMessage"></v-preloader>

		<div class="container" v-if="applications">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['ReferenceManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__title--element">
										<div class="content__title--text">
											{{ $t('sidebar.references') }}
										</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<div class="table__block--content">
									<div class="table__block--filter">
										<div class="table__filter--left">
											<div class="table__filter--search">
												<label class="search__label">
													<input
														type="text"
														value=""
														name="filter_search"
														v-model="filters.search"
														:placeholder="$t('placeholder.search')"
													/>
													<button>
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
													</button>
												</label>
											</div>
											<div class="table__filter--order">
												<multiselect
													class="select__status"
													v-model="filterCategories"
													:options="orderList"
													label="name"
													:clearOnSelect="false"
													:clear-on-select="false"
													:placeholder="$t('placeholder.choose')"
												>
													<template slot="singleLabel" slot-scope="{ option }">{{
														option.name
													}}</template>
													<template slot="noResult">{{ $t('components.not_found') }}</template>
												</multiselect>
											</div>
										</div>
										<div class="table__filter--right">
											<router-link
												v-if="$store.getters.userPermission(['change_catalog'])"
												:to="'/' + $i18n.locale + '/account/references/create'"
												class="btn--link btn--link--plus"
											>
												{{ $t('button.create_handbook') }}
												<img src="../../../assets/img/icon-plus.svg" />
											</router-link>
										</div>
									</div>

									<v-data-table
										:headers="headers"
										:items="filteredlist"
										:loading="false"
										:options.sync="options"
										:footer-props="{
											'items-per-page-options': [5, 10, 20, 30, 40, 50],
										}"
									>
										<template v-slot:item.name="{ item }">
											<router-link
												:to="'/' + $i18n.locale + '/account/references/' + item.id"
												class="tb__table--link"
											>
												{{ $t('handbooks.' + item.name) }}
											</router-link>
										</template>
										<template v-slot:item.created_at="{ item }">
											{{ item.created_at | formatDate }}
										</template>
										<template v-slot:item.status="{ item }">
											<a
												class="tb__table--status"
												:class="{
													'tb__status--success': item.status == 'active',
													'tb__status--error': item.status == 'deleted',
												}"
											>
												{{ $t('status.' + item.status) }}
											</a>
										</template>
										<template v-slot:item.action="{ item }">
											<router-link
												v-if="$store.getters.userPermission(['change_catalog'])"
												:to="'/' + $i18n.locale + '/account/references/' + item.id + '/list'"
												class="tb__table--btn tb__table--list"
												v-tooltip.top-center="{
													content: $t('labels.handbooks'),
													class: ['tooltip__btn'],
												}"
											>
												<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 3h1.5A1.5 1.5 0 0115 4.5V15a1.5 1.5 0 01-1.5 1.5h-9A1.5 1.5 0 013 15V4.5A1.5 1.5 0 014.5 3H6" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M11.25 1.5h-4.5a.75.75 0 00-.75.75v1.5a.75.75 0 00.75.75h4.5a.75.75 0 00.75-.75v-1.5a.75.75 0 00-.75-.75zM9 8.25h3M9 12h3M6 8.25h.008M6 12h.008" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
											</router-link>
											<router-link
												v-if="$store.getters.userPermission(['change_catalog'])"
												:to="'/' + $i18n.locale + '/account/references/' + item.id"
												class="tb__table--btn tb__table--view"
												v-tooltip.top-center="{
													content: $t('tooltip.look'),
													class: ['tooltip__btn'],
												}"
											>
												<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.75 9s3-6 8.25-6 8.25 6 8.25 6-3 6-8.25 6S.75 9 .75 9z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
											</router-link>
											<router-link
												v-if="$store.getters.userPermission(['change_catalog'])"
												:to="'/' + $i18n.locale + '/account/references/' + item.id + '/edit'"
												class="tb__table--btn tb__table--edit"
												v-tooltip.top-center="{
													content: $t('tooltip.edit'),
													class: ['tooltip__btn'],
												}"
											>
												<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9 15h6.75M12.375 2.625a1.591 1.591 0 112.25 2.25L5.25 14.25l-3 .75.75-3 9.375-9.375z" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
											</router-link>
										</template>
										<v-alert slot="no-results" :value="true" color="error">
											{{ $t('system_message.search') }}
										</v-alert>
									</v-data-table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import { api } from '../../boot/axios'
import Multiselect from 'vue-multiselect'
import { api } from '@/boot/axios'

export default {
	components: {
		Multiselect,
	},
	data() {
		return {
			applications: null,
			errorMessage: {
				status: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},

			filters: {
				search: '',
			},
			options: {
				itemsPerPage: 10,
				page: 1,
			},

			Categories: 'all',
			filterCategories: this.$t('orders_status.userList'),
			orderList: this.$t('orders_status.user'),

			headers: [
				{ text: 'Id', value: 'id' },
				{ text: this.$t('headers.name'), value: 'name' },
				{ text: this.$t('headers.created_at'), value: 'created_at' },
				{ text: this.$t('headers.status'), value: 'status' },
				{
					text: this.$t('headers.action'),
					value: 'action',
					align: 'center',
					sortable: false,
					width: '200px',
				},
			],
		}
	},
	methods: {
		apiGetCatalogList() {
			api
				.get('catalog/list')
				.then((response) => {
					if (response.data) {
						this.applications = response.data.data
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},
	},
	computed: {
		filteredlist() {
			return this.applications.filter((d) => {
				if (
					((d.name ? d.name.toLowerCase().includes(this.filters.search.toLowerCase()) : true) ||
						(this.$options.filters.formatDate(d.created_at)
							? this.$options.filters
									.formatDate(d.created_at)
									.toLowerCase()
									.includes(this.filters.search.toLowerCase())
							: true)) &&
					(this.filterCategories.status == 'all' || d.status == this.filterCategories.status)
				) {
					return true
				}
			})
		},
	},
	beforeMount() {
		this.apiGetCatalogList()
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
	head: {
		title() {
			return {
				inner: this.$t('sidebar.references'),
			}
		},
		meta: [],
	},
}
</script>
